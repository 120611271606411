@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link-menu{
  @apply mb-2 hover:bg-slate-400 hover:px-2 hover:text-slate-50 hover:rounded-md cursor-pointer hover:py-1
}
.link-menu-active{
  @apply mb-2 bg-slate-400 px-2 text-slate-50 rounded-md cursor-pointer py-1
}
.none{
  display: none;
}
.bg-modal{
  background-color: rgba(0, 0, 2, 0.5);
}
.form-control-auth{
  @apply mt-1 px-3 py-2 border-2 shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1
}
.form-control{
  @apply mt-1 px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1
}
.form-control-search{
  @apply px-3 py-2 bg-white sm:text-sm focus:outline-none
}
.form-label-o{
  @apply after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700
}
.form-label{
  @apply  block text-sm font-medium text-slate-700
}

.form-control__input{
  width: 100%;
  position: relative;
}
.form-control__input:focus-visible{
  outline: none;
}

.form-control__value {
  position: relative;
  z-index: 1;
}

.form-control:not(.is-loading):not(.form-control--multiple) .form-control__value::after {
  transform: rotate(45deg);
  border-right: 2px solid #334155;
  border-bottom: 2px solid #334155;
  pointer-events: none;
  cursor: pointer;
}

.form-control.is-loading .form-control__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.form-control__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 4px);
  right: -3px;
  width: 6px;
  height: 6px;
  cursor: pointer;
}

.form-control:not(.form-control--multiple) .form-control__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
}

.form-control--multiple .form-control__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.form-control__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.form-control__option.is-highlighted.is-selected,
.form-control__option.is-selected:hover {
  background: #1E90FF;
  color: #fff;
  width: 100%;
  text-align: start;
}

.form-control__option.is-selected {
  background: #1E90FF;
  color: #fff;
  width: 100%;
  text-align: start;
}

.form-control__option.is-highlighted,
.form-control__option:not(.is-selected):hover {
  background: rgba(30, 144, 255, 0.5);
  width: 100%;
  text-align: start;
}